require('./bootstrap');
import 'jquery-ui/themes/base/all.css';
import 'jquery-ui/ui/widgets/autocomplete.js';

var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

$(document).ready(function() {
    $('.collapse-rotate-icon').on('show.bs.collapse', function (event) {
        event.stopPropagation();
        $('#rotate-' + this.id).addClass('rotate-180');
    })
    $('.collapse-rotate-icon').on('hide.bs.collapse', function (event) {
        event.stopPropagation();
        $('#rotate-' + this.id).removeClass('rotate-180');
    })
    $('.toTopBtn').click(function(){
      $([document.documentElement, document.body]).animate({
        scrollTop: 0
        }, 666);
    });

    if ($('#cities_search')) {
        $('#cities_search').autocomplete({
        source: function( request, response ) {
          // Fetch data
          $.ajax({
            url:"/api/cities",
            type: 'POST',
            dataType: "json",
            data: {
               _token: CSRF_TOKEN,
               search: request.term
            },
            success: function( data ) {
               response( data.data );
            },
          });
        },
        minLength: 3,
        response: function( event, ui ) {
        },
        select: function (event, ui) {
           // Set selection
           $('#cities_search').val(ui.item.label); // display the selected text
           // $('#employeeid').val(ui.item.value); // save selected id to input
           return false;
        }
      });
    }
});
